<template>
	<main class="vcc-main">
		<router-link :to="{ name: 'OrganizationRegistry' }">← Назад</router-link><br><br>
		<h1 class="heading">Список добавленных организаций</h1>
		<ul class="past-webinars--list">
			<li v-for="record, key in getRecords" :key="'record'+key+'-'+record.id">
				<organization-card :card-info="record" :link="{  name: 'OrganizationPage', params: { id: record.id } }"/>
			</li>
		</ul>
		<section class="button-wrapper" v-if="getRecords.length > 0">
			<xlsx-workbook>
				<xlsx-sheet
					:collection="sheetData"
					sheet-name="OrganizationRegistry" />
				<xlsx-download filename="реестр_организаций.xlsx">
					<button type="button" class="btn__blue">Скачать .xlsx</button>
				</xlsx-download>
			</xlsx-workbook>
		</section>
	</main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "../../../node_modules/vue-xlsx/dist/vue-xlsx.es.js"
import orgregRusKeys from '@/helpers/lib/orgregRusKeys.json'
import OrganizationCard from '@/components/cards/OrganizationCard.vue'
export default {
	orgregRusKeys,
	name: 'OrganizationList',
	components: {
		OrganizationCard,
		XlsxWorkbook,
		XlsxSheet,
		XlsxDownload
	},
	computed: {
		...mapGetters(['getRecords']),
		sheetData() {
			const rusRecords = []
			this.getRecords.forEach((record) => {
				const obj = {}
				const cp = orgregRusKeys.filter((k) => k.contact_person)[0].contact_person
				const contact_person = {}
				Object.keys(record.contact_person).forEach((key) => {
						contact_person[cp.filter((k) => k[key])[0][key]] = record.contact_person[key]
				})
				Object.keys(record).forEach((key) => {
					if(key !== 'contact_person') obj[orgregRusKeys.filter((k) => k[key])[0][key]] = record[key]
				})
				rusRecords.push({...obj, ...contact_person})
			})
			return rusRecords
		}
	},
	methods: mapActions(['fetchRecords', 'fetchFields']),
	created() {
		this.fetchRecords()
		this.fetchFields()
	}
}
</script>
<style scoped>
.vcc-main {
	padding: 0 10px;
}
.button-wrapper {
	position: relative;
}

.button-wrapper button {
	position: fixed;
	bottom: 45px;
	right: 30px;
}
</style>
